<template>
  <!-- 合作学习墙 活动数据 -->
  <div class="activity-child-data manage-wrapper">
    <ul class="learning-count-list flex-align-between">
      <li v-for="(item,index) in dataList" :key="index" class="learning-count-item flex-align">
        <img :src="item.icon" alt="" class="learning-data-icon">
        <div>
          <p class="learning-data-number">{{item.count}}<span v-if="item.name=='参与度'"
              class="learning-data-unit">%</span>
          </p>
          <p class="learning-data-name">{{item.name}}</p>
        </div>
      </li>
    </ul>
    <p class="tips">*更新说明：非实时更新，作品提交后24小时更新统计数据</p>
    <div class="table-container">
      <el-form :inline="true" :model="formInline" class="demo-form-inline flex-layout">
        <el-form-item class="filter-school">
          <el-select v-model="formInline.school" placeholder="项目学校" size="mini" style="width: 120px;"
            @change="filterInfo">
            <el-option label="全部" value="">全部</el-option>
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in schoolList" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="filter-search">
          <el-input v-model="formInline.search" size="mini" placeholder="请输入姓名/手机号搜索" suffix-icon="el-icon-search"
            @input="filterInfo">
          </el-input>
        </el-form-item>
        <!-- <el-form-item class="filter-btn">
          <el-button class="search-btn btn" size="mini">搜索</el-button>
        </el-form-item> -->
        <el-form-item class="filter-download csp">
          <div @click="exportFile">
            <img src="@/assets/images/active/data/child-data-download.png" alt="" class="download-icon">
            <span>下载表格</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="table-wrap" ref="table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="姓名" width="120" align="center" show-overflow-tooltip fixed>
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center" width="110" fixed>
          </el-table-column>
          <el-table-column prop="schoolName" label="项目校" align="center" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="workCount" label="作品数" align="center">
          </el-table-column>
          <el-table-column prop="wordsCount" label="总字数" align="center">
          </el-table-column>
          <el-table-column prop="excellentWords" label="优秀" align="center" show-overflow-tooltip width="110">
            <template slot-scope="scope">
              <span v-for="item,index in scope.row.excellentWords" :key="index">{{item.charName}}<i
                  v-if="index!=scope.row.excellentWords.length-1">，</i></span>
            </template>
          </el-table-column>
          <el-table-column prop="goodWords" label="良好" align="center" show-overflow-tooltip width="110">
            <template slot-scope="scope">
              <span v-for="item,index in scope.row.goodWords" :key="index">{{item.charName}}<i
                  v-if="index!=scope.row.goodWords.length-1">，</i></span>
            </template>
          </el-table-column>
          <el-table-column prop="badWords" label="待提升" align="center" show-overflow-tooltip width="110">
            <template slot-scope="scope">
              <span v-for="item,index in scope.row.badWords" :key="index">{{item.charName}}<i
                  v-if="index!=scope.row.badWords.length-1">，</i></span>
            </template>
          </el-table-column>
          <el-table-column prop="likesCount" label="点赞数" align="center">
          </el-table-column>
          <el-table-column prop="postCount" label="发帖数" align="center">
          </el-table-column>
          <el-table-column prop="replyCount" label="回复数" align="center">
          </el-table-column>
        </el-table>
      </div>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" :pageSizes="[10,20,30]" @pagination="handlePageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formInline: {
          school: undefined,
          search: undefined
        },
        pages: { //分页
          currentPage: 1,
          pageSize: 10,
          total: 0,
        },
        tableData: [],
        schoolList: [],
        dataList: [{
            title: 'join',
            name: '参与度',
            count: '0',
            icon: require("@/assets/images/active/data/child-data-icon1.png"),
          },
          {
            title: 'activityMemberCount',
            name: '参与人数',
            count: '0',
            icon: require("@/assets/images/active/data/child-data-icon2.png"),
          },
          {
            title: 'workCount',
            name: '作品数',
            count: '0',
            icon: require("@/assets/images/active/data/child-data-icon3.png"),
          },
          {
            title: 'likesCount',
            name: '点赞',
            count: '0',
            icon: require("@/assets/images/active/data/child-data-icon4.png"),
          },
          {
            title: 'postCount',
            name: '发帖数',
            count: '0',
            icon: require("@/assets/images/active/data/child-data-icon5.png"),
          },
          {
            title: 'replyCount',
            name: '回复数',
            count: '0',
            icon: require("@/assets/images/active/data/child-data-icon6.png"),
          }
        ],
      }
    },

    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        this.getChildActivityList();
      },
      filterInfo(val) {
        this.getChildActivityList();
      },
      // 下载表格
      async exportFile() {
        let params = {
          id: this.$route.params.id
        }
        this.formInline.school ? this.$set(params, 'schoolId', this.formInline.school) : null;
        this.formInline.search ? this.$set(params, 'phone', this.formInline.search) : null;
        // let resData = await this.$Api.Activity.exportActivityCountList(params)
        // console.log(resData);
        this.$message.info('正在导出...')
        let paramsStr = '';
        for (const key in params) {
          paramsStr += key == 'id' ? `?${key}=${params[key]}` : `&${key}=${params[key]}`
        }
        // console.log(paramsStr);
        window.location.href =
          `${this.commonbaseURL}/course/activity/activityCountListEx/export${paramsStr}`;
      },
      async getChildActivityList() {
        let params = {
          id: Number(this.$route.params.id),
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        }
        params.schoolId = this.formInline.school ? this.formInline.school : null;
        params.phone = this.formInline.search ? this.formInline.search : null;
        let resData = await this.$Api.Activity.getActivityCountList(params);
        console.log(resData.data);
        this.dataList.map(item => {
          item.count = resData.data[item.title]
          if (item.title == 'join') {
            item.count = ((resData.data.activityMemberCount / resData.data.memberCount) * 100).toFixed(2)
          }
        })
        this.tableData = resData.data.list;
        this.schoolList = resData.data.schoolVos;
        this.pages.total = resData.data.total
        for (let i = 0; i < this.tableData.length; i++) {
          let wordsList = this.tableData[i].words;
          let excellentList = []
          let goodList = []
          let badList = []
          for (let k = 0; k < wordsList.length; k++) {
            if (wordsList[k].score >= 80) excellentList.push(wordsList[k])
            if (wordsList[k].score < 80 && wordsList[k].score >= 65) goodList.push(wordsList[k])
            if (wordsList[k].score < 65) badList.push(wordsList[k])
          }
          this.$set(this.tableData[i], 'excellentWords', excellentList);
          this.$set(this.tableData[i], 'goodWords', goodList);
          this.$set(this.tableData[i], 'badWords', badList);
        }
        // console.log(this.tableData);
      },
    },
    mounted() {
      this.getChildActivityList();
    }
  }
</script>

<style lang="less" scoped>
  .activity-child-data {

    .learning-count-item {
      flex: 1;
      margin-right: 17px;
      border: 1px solid #F2F2F2;
      border-radius: 4px;
      padding: 22px 0 22px 25px;
      box-sizing: border-box;
      background-color: #fff;
      line-height: 1;


      .learning-data-icon {
        width: 31px;
        height: 31px;
        margin-right: 14px;
        background-size: 100% 100%;
        flex-shrink: 0;
      }

      .learning-data-name {
        font-size: 13px;
        color: #999999;
      }

      .learning-data-number {
        font-size: 20px;
        margin-bottom: 10px;


        .learning-data-unit {
          font-size: 17px;
        }
      }


      &:nth-child(1) {
        .learning-data-number {
          color: #FFC320;
        }
      }

      &:nth-child(2) {
        .learning-data-number {
          color: #00C4C2;
        }
      }

      &:nth-child(3) {
        .learning-data-number {
          color: #FE6C53;
        }
      }

      &:nth-child(4) {
        .learning-data-number {
          color: #B198DC;
        }
      }

      &:nth-child(5) {
        .learning-data-number {
          color: #3DD76C;
        }
      }

      &:nth-child(6) {
        margin-right: 0;

        .learning-data-number {
          color: #41C9FF;
        }
      }
    }

    .tips {
      font-size: 12px;
      color: #999999;
      line-height: 49px;
    }

    .table-container {
      border: 1px solid #F2F2F2;
      border-radius: 8px;
      padding: 26px 35px 50px;

      .demo-form-inline {
        justify-content: flex-end;


        .filter-school,
        .filter-btn {
          margin-right: 36px;
        }

        .filter-search {
          margin-right: 16px;
        }

        .search-btn {
          background: #508EF9;
          border-radius: 4px;
          color: #fff;
          border: 0;
          padding: 0 34px;
          line-height: 28px;
        }

        .filter-download {
          color: #999;
          font-size: 12px;
        }

        .download-icon {
          vertical-align: text-top;
          margin-right: 6px;
        }
      }


      .table-wrap {
        border: 1px solid #F2F2F2;
        margin-bottom: 30px;

        /deep/ .el-table__body-wrapper {

          /*修改滚动条样式*/
          &::-webkit-scrollbar {
            height: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(191, 191, 191, .5);
            border-radius: 15px;
          }
        }

        /deep/.el-table th {
          background-color: #FAFBFF;
          color: #6E6E6F;

          &>.cell {
            white-space: pre-line;
          }
        }

        .open-table-btn {
          width: 100%;
          display: block;
          line-height: 40px;
          color: #999999;
          font-size: 12px;
          text-align: center;
        }

        .tansition-box {
          transition: height .5s;
          overflow: hidden;
        }

      }
    }

    @media screen and (min-width :1250px) {
      .learning-count-item {
        margin-right: 22px;
        padding: 25px 0 25px 30px;
        box-sizing: border-box;
        background-color: #fff;
        line-height: 1;


        .learning-data-icon {
          width: 38px;
          height: 38px;
          margin-right: 21px;
        }

        .learning-data-name {
          font-size: 15px;
        }

        .learning-data-number {
          font-size: 24px;
          margin-bottom: 12px;


          .learning-data-unit {
            font-size: 16px;
          }
        }
      }

      .tips {
        font-size: 14px;
        line-height: 59px;
      }
    }
  }
</style>