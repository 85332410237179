import { render, staticRenderFns } from "./activity_data_wall.vue?vue&type=template&id=6a239099&scoped=true"
import script from "./activity_data_wall.vue?vue&type=script&lang=js"
export * from "./activity_data_wall.vue?vue&type=script&lang=js"
import style0 from "./activity_data_wall.vue?vue&type=style&index=0&id=6a239099&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a239099",
  null
  
)

export default component.exports